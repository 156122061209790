/* eslint-disable react/no-danger */
import dynamic from 'next/dynamic';
import { END } from 'redux-saga';
import { useEffect, useMemo } from 'react';
import { actions, GET_DETAIL_PROJECT } from '@containers/Home/slice';
import { wrapper, SagaStore } from 'store';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConfigLittlePlanetProject,
  selectConfigFirstSceneProject,
  selectFirstSceneMediaProject,
  selectGATagTrackingProject,
  selectThumbnailProject,
  selectGATrackingProject,
  selectListSceneByGroup,
  selectMetaDataProject,
  selectTitleProject,
  selectFeatureImageProject,
  selectTenant,
} from '@containers/Home/selectors';
import Script from 'next/script';
import {
  LIST_FAVICON_DEFAULT,
  LIST_LINK_STYLE_SHEET,
} from '@containers/Home/constants';
import { parse } from 'cookie';
import { KEY_COOKIE } from '@type/customType';

const Home = dynamic(() => import('containers/Home'), {
  loading: () => <span>Loading</span>,
});

interface Props {
  host?: string;
  passwordTourCookie?: string;
  listProjectGuardOpenedCookie?: string;
  listFormCaptureOpenedCookie?: string;
}

function PublicContainer({
  host,
  passwordTourCookie,
  listProjectGuardOpenedCookie,
  listFormCaptureOpenedCookie,
}: Props) {
  const router = useRouter();
  const dispatch = useDispatch();

  const tenant = useSelector(selectTenant);
  const title = useSelector(selectTitleProject);
  const metadata = useSelector(selectMetaDataProject);
  const listScene = useSelector(selectListSceneByGroup);
  const thumbnail = useSelector(selectThumbnailProject);
  const ga_tracking = useSelector(selectGATrackingProject);
  const feature_image = useSelector(selectFeatureImageProject);
  const first_scene = useSelector(selectConfigFirstSceneProject);
  const ggtag_tracking = useSelector(selectGATagTrackingProject);
  const first_scene_media = useSelector(selectFirstSceneMediaProject);
  const config_little_planet = useSelector(selectConfigLittlePlanetProject);

  const detailFirstScene = useMemo(
    () => listScene.filter(item => item.id === first_scene)[0] || undefined,
    [listScene, first_scene],
  );

  const images = useMemo(() => {
    const imgs: any = [];
    if (thumbnail?.src)
      imgs.push({
        url: thumbnail?.src,
        width: 800,
        height: 600,
        alt: thumbnail?.name,
      });

    if (feature_image)
      imgs.push({
        url: feature_image,
        width: 800,
        height: 600,
        alt: '',
      });

    if (first_scene_media?.src)
      imgs.push({
        url:
          first_scene_media?.src_thumb ||
          first_scene_media?.src_preview ||
          first_scene_media?.src,
        width: 800,
        height: 600,
        alt: first_scene_media?.name,
      });

    return imgs;
  }, [thumbnail, feature_image, first_scene_media]);

  useEffect(() => {
    if (listScene && listScene.length) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      let scene = detailFirstScene
        ? { ...detailFirstScene }
        : { ...listScene[0] };

      if (router.query.scene)
        scene = {
          ...listScene.filter(item => item.id === router.query.scene)[0],
        } || {
          ...listScene[0],
        };

      if (params.scene) {
        const sceneMatched =
          listScene.find(item => item.id === params.scene) || listScene[0];
        if (sceneMatched) scene = { ...sceneMatched };
      }

      // NOTE: add intro little planet for first scene loaded
      scene.config = scene?.config || {};
      scene.config = {
        ...scene.config,
        isLittlePlanet: config_little_planet?.enable || false,
        timeTransitionLittlePlanet: config_little_planet?.transition_time || 2,
      };
      dispatch(actions.setSceneSelected(scene));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listScene.length]);

  return (
    <>
      {tenant && (
        <NextSeo
          title={metadata?.title || title}
          description={metadata?.description || tenant?.description}
          canonical={host}
          additionalMetaTags={[
            {
              property: 'name',
              content: tenant?.name,
            },
            {
              name: 'application-name',
              content: tenant?.name,
            },
          ]}
          additionalLinkTags={
            metadata?.favicon || !tenant?.domain_public?.includes('panoee')
              ? [
                  ...[
                    {
                      rel: 'shortcut icon',
                      href: metadata?.favicon || tenant?.favicon?.src,
                    },
                  ],
                  ...(tenant?.domain_public?.includes('panoee')
                    ? LIST_LINK_STYLE_SHEET
                    : []),
                ]
              : [...LIST_FAVICON_DEFAULT, ...LIST_LINK_STYLE_SHEET]
          }
          openGraph={{
            type: 'website',
            url: host
              ? `https://${host}`
              : tenant?.domain_public + router.asPath,
            title: metadata?.title || title,
            description: metadata?.description || tenant?.description,
            images,
            site_name: tenant?.name,
            profile: {
              username: router.query.username as string,
            },
          }}
          facebook={{
            appId: process.env.NEXT_PUBLIC_FB_APP_ID || '2086263614924092',
          }}
        />
      )}
      {ga_tracking && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${ga_tracking}`}
          />
          <Script
            id="gg-tag"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${ga_tracking}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}
      {ggtag_tracking && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${ggtag_tracking}`}
          />
          <Script
            id="gg-tag-1"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${ggtag_tracking}');
              `,
            }}
          />
        </>
      )}
      <Home
        isPublic
        isPreventChangeUrl
        passwordTourCookie={passwordTourCookie}
        listProjectGuardOpenedCookie={listProjectGuardOpenedCookie}
        listFormCaptureOpenedCookie={listFormCaptureOpenedCookie}
      />
    </>
  );
}

export const getServerSideProps = wrapper.getServerSideProps(
  store =>
    async ({ req, res }) => {
      const cookies = parse(req.headers.cookie || '');
      const cookieProps = {
        listProjectGuardOpenedCookie: cookies
          ? cookies[KEY_COOKIE.LIST_PROJECT_PASS_GUARD] || null
          : null,
        passwordTourCookie: cookies
          ? cookies[KEY_COOKIE.PASSWORD_TOUR] || null
          : null,
        listFormCaptureOpenedCookie: cookies
          ? cookies[KEY_COOKIE.LIST_FORM_CAPTURE_OPENED] || null
          : null,
      };
      console.log('req.headers.host :', req.headers.host);
      if (
        req.headers.host &&
        (req.headers.host.includes('localhost') ||
          !req.headers.host.includes('panoee.com'))
      ) {
        store.dispatch(
          GET_DETAIL_PROJECT({
            domain: req.headers.host,
            host: req.headers.host,
            isPublic: true,
          }),
        );
        store.dispatch(END);
        await (store as SagaStore).sagaTask?.toPromise();
        return {
          props: {
            host: req.headers.host,
            ...cookieProps,
          },
        };
      }
      res.writeHead(301, { Location: 'https://panoee.com' });
      res.end();
      return {
        props: {
          host: req.headers.host,
          ...cookieProps,
        },
      };
    },
);

export default PublicContainer;
